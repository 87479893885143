var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validationObserver"},[_c('image-crop',{ref:"cropper",attrs:{"image":_vm.imageToCrop,"aspect-ratio":1920/900},on:{"cropped":_vm.upload,"hidden":_vm.clearImageToCrop}}),_c('b-card-form',[_c('div',[_c('div',[_c('b-form',{ref:"form",staticClass:"repeater-form",style:({ height: _vm.trHeight }),on:{"submit":function($event){$event.preventDefault();return _vm.repeatAgain.apply(null, arguments)}}},_vm._l((_vm.items),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"5"}},[_c('b-form-group',{attrs:{"label":"English Title","label-for":"english-title"}},[_c('validation-provider',{attrs:{"vid":'english-title' + item.id,"rules":"required","name":"English Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"english-title","type":"text","state":errors.length > 0 ? false : null,"placeholder":"English Title"},model:{value:(item.titleEn),callback:function ($$v) {_vm.$set(item, "titleEn", $$v)},expression:"item.titleEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"5","dir":"rtl"}},[_c('b-form-group',{attrs:{"label":"Arabic Title","label-for":"arabic-title"}},[_c('validation-provider',{attrs:{"vid":'arabic-title' + item.id,"rules":"required","name":"Arabic Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"arabic-title","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Arabic Title"},model:{value:(item.titleAr),callback:function ($$v) {_vm.$set(item, "titleAr", $$v)},expression:"item.titleAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"lg":"2","md":"3"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Delete")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"5"}},[_c('b-form-group',{attrs:{"label":"Button English Title","label-for":"btn-english-title"}},[_c('validation-provider',{attrs:{"vid":'btn-english-title' + item.id,"rules":"required","name":"Button English Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"btn-english-title","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Button English Title"},model:{value:(item.buttonTitleEn),callback:function ($$v) {_vm.$set(item, "buttonTitleEn", $$v)},expression:"item.buttonTitleEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"5","dir":"rtl"}},[_c('b-form-group',{attrs:{"label":"Button Arabic Title","label-for":"btn-arabic-title"}},[_c('validation-provider',{attrs:{"vid":'btn-arabic-title' + item.id,"rules":"required","name":"Button Arabic Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"btn-arabic-title","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Button Arabic Title"},model:{value:(item.buttonTitleAr),callback:function ($$v) {_vm.$set(item, "buttonTitleAr", $$v)},expression:"item.buttonTitleAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"5"}},[_c('b-form-group',{attrs:{"label":"Button English Url","label-for":"btn-english-url"}},[_c('validation-provider',{attrs:{"vid":'btn-english-url' + item.id,"rules":"required","name":"Button English Url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"btn-english-url","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Button English Url"},model:{value:(item.buttonUrlEn),callback:function ($$v) {_vm.$set(item, "buttonUrlEn", $$v)},expression:"item.buttonUrlEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"5","dir":"rtl"}},[_c('b-form-group',{attrs:{"label":"Button Arabic Url","label-for":"btn-arabic-url"}},[_c('validation-provider',{attrs:{"vid":'btn-arabic-url' + item.id,"rules":"required","name":"Button Arabic Url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"btn-arabic-url","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Button Arabic Url"},model:{value:(item.buttonUrlAr),callback:function ($$v) {_vm.$set(item, "buttonUrlAr", $$v)},expression:"item.buttonUrlAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('hr',{staticClass:"mb-2"}),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"5"}},[_c('label',[_vm._v("English Image")]),_c('b-form-file',{attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.loadImage($event, item, 'mainPhotoUrlEn')}}}),(item.mainPhotoUrlEn)?_c('div',{staticClass:"mt-3"},[_c('b-img',{attrs:{"fluid":"","src":item.mainPhotoUrlEn,"alt":"English Image"}})],1):_vm._e()],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"5"}},[_c('label',[_vm._v("Arabic Image")]),_c('b-form-file',{attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.loadImage($event, item, 'mainPhotoUrlAr')}}}),(item.mainPhotoUrlAr)?_c('div',{staticClass:"mt-3"},[_c('b-img',{attrs:{"fluid":"","src":item.mainPhotoUrlAr,"alt":"Arabic Image"}})],1):_vm._e()],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)}),1)],1),_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.repeatAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add New")])],1)],1),_c('b-col',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save(false)}}},[_c('span',[_vm._v("Save")])])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-tabs>
    <b-tab active>
      <template #title>
        <feather-icon icon="HomeIcon"/>
        <span>Main Slider</span>
      </template>

      <homepage-main-slider ref="mainSlider"></homepage-main-slider>
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="ToolIcon"/>
        <span>We Believe</span>
      </template>

      <homepage-we-believe ref="weBelieve"></homepage-we-believe>
    </b-tab>

    <template #tabs-end>
      <div class="ml-auto">
        <b-row>
          <b-col>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                right
                @click.prevent="preview"
            >
              Preview
            </b-button>
          </b-col>
          <b-col>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                right
                @click.prevent="publish"
            >
              Publish
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>

  </b-tabs>
</template>

<script>
import {
  BTabs, BTab, BButton, BCol, BRow,
} from 'bootstrap-vue';
import HomepageMainSlider from '@/views/cms/homepage/HomepageMainSlider';
import HomepageWeBelieve from '@/views/cms/homepage/HomepageWeBelieve';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    BCol,
    BRow,
    HomepageMainSlider,
    HomepageWeBelieve
  },
  directives: {
    Ripple,
  },
  methods: {
    preview() {
      Promise.all([
        this.$refs.mainSlider.save(true),
        this.$refs.weBelieve.save(true)
      ])
          .then(values => {
            if (values.every(element => element === true)) {
              window.open(`${process.env.VUE_APP_PREVIEW_URL}?preview=true`)
            }
          });
    },
    async publish() {
      await Promise.all([
        this.$refs.mainSlider.save(true),
        this.$refs.weBelieve.save(true)
      ])
      await this.$http.post('/homepage/publish');

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Published Successfully',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      });
    },
  },
}
</script>

<template>
  <validation-observer ref="validationObserver">
    <image-crop ref="cropper"
                :image="imageToCrop"
                @cropped="upload"
                :aspect-ratio="4000/2800"
                @hidden="clearImageToCrop" />

    <b-card-form>

      <!-- Title and Text -->
      <b-row>
        <b-col md="6" xl="6" class="mb-1">
          <b-form-group label="English Title" label-for="english-title">
            <validation-provider #default="{ errors }" rules="required" name="English Title">
              <b-form-input
                  id="english-title"
                  v-model="data.descriptionEn"
                  :state="errors.length > 0 ? false:null"
                  placeholder="English Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6" xl="6" class="mb-1">
          <b-form-group label="Arabic Title" label-for="arabic-title">
            <validation-provider #default="{ errors }" rules="required" name="Arabic Title">
              <b-form-input
                  id="arabic-title"
                  v-model="data.descriptionAr"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Arabic Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6" xl="6" class="mb-1">
          <b-form-group label="English Text" label-for="english-text">
            <validation-provider #default="{ errors }" rules="required" name="English Text">
              <b-form-textarea
                  id="english-text"
                  v-model="data.textEn"
                  :state="errors.length > 0 ? false:null"
                  placeholder="English Text"
                  rows="6"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6" xl="6" class="mb-1" dir="rtl">
          <b-form-group label="Arabic Text" label-for="arabic-text">
            <validation-provider #default="{ errors }" rules="required" name="Arabic Text">
              <b-form-textarea
                  id="arabic-text"
                  v-model="data.textAr"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Arabic Text"
                  rows="6"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

      </b-row>

      <hr class="mb-2">

      <!-- First Button -->
      <b-row>
        <b-col md="6" xl="6" class="mb-1">
          <b-form-group label="First Button English Title" label-for="fb-english-title">
            <validation-provider #default="{ errors }" rules="required" name="First Button English Title">
              <b-form-input
                  id="fb-english-title"
                  v-model="data.firstButtonTitleEn"
                  :state="errors.length > 0 ? false:null"
                  placeholder="First Button English Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6" xl="6" class="mb-1" dir="rtl">
          <b-form-group label="First Button Arabic Title" label-for="fb-arabic-title">
            <validation-provider #default="{ errors }" rules="required" name="First Button Arabic Title">
              <b-form-input
                  id="fb-arabic-title"
                  v-model="data.firstButtonTitleAr"
                  :state="errors.length > 0 ? false:null"
                  placeholder="First Button Arabic Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

      </b-row>

      <b-row>
        <b-col md="6" xl="6" class="mb-1">
          <b-form-group label="First Button English Url" label-for="fb-english-url">
            <validation-provider #default="{ errors }" rules="required" name="First Button English Url">
              <b-form-input
                  id="fb-english-url"
                  v-model="data.firstButtonUrlEn"
                  :state="errors.length > 0 ? false:null"
                  placeholder="First Button English Url"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6" xl="6" class="mb-1" dir="rtl">
          <b-form-group label="First Button Arabic Url" label-for="fb-arabic-url">
            <validation-provider #default="{ errors }" rules="required" name="First Button Arabic Url">
              <b-form-input
                  id="fb-arabic-url"
                  v-model="data.firstButtonUrlAr"
                  :state="errors.length > 0 ? false:null"
                  placeholder="First Button Arabic Url"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

      </b-row>

      <hr class="mb-2">

      <!-- Second Button -->
      <b-row>
        <b-col md="6" xl="6" class="mb-1">
          <b-form-group label="Second Button English Title" label-for="sb-english-title">
            <validation-provider #default="{ errors }" rules="required" name="Second Button English Title">
              <b-form-input
                  id="sb-english-title"
                  v-model="data.secondButtonTitleEn"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Second Button English Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6" xl="6" class="mb-1" dir="rtl">
          <b-form-group label="Second Button Arabic Title" label-for="sb-arabic-title">
            <validation-provider #default="{ errors }" rules="required" name="Second Button Arabic Title">
              <b-form-input
                  id="sb-arabic-title"
                  v-model="data.secondButtonTitleAr"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Second Button Arabic Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

      </b-row>

      <b-row>
        <b-col md="6" xl="6" class="mb-1">
          <b-form-group label="Second Button English Url" label-for="sb-english-url">
            <validation-provider #default="{ errors }" rules="required" name="Second Button English Url">
              <b-form-input
                  id="sb-english-url"
                  v-model="data.secondButtonUrlEn"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Second Button English Url"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6" xl="6" class="mb-1" dir="rtl">
          <b-form-group label="Second Button Arabic Url" label-for="sb-arabic-url">
            <validation-provider #default="{ errors }" rules="required" name="Second Button Arabic Url">
              <b-form-input
                  id="sb-arabic-url"
                  v-model="data.secondButtonUrlAr"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Second Button Arabic Url"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

      </b-row>

      <hr class="mb-2">

      <!-- Image -->
      <b-row>
        <b-col md="6" class="mb-1">
          <label for="wildcard">Image</label>
          <b-form-file type="file" @change="loadImage" accept="image/*"/>
        </b-col>
      </b-row>

      <b-row v-if="data.photoUrl" class="mt-3">
        <b-col md="6" class="mb-1">
          <b-img
              fluid
              :src="data.photoUrl"
              alt="image"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col class="text-right">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="save(false)"
          >
            <span>Save</span>
          </b-button>
        </b-col>
      </b-row>

    </b-card-form>
  </validation-observer>
</template>

<script>
import BCardForm from '@/components/b-card-form'
import {
  BFormInput, BRow, BCol, BFormGroup, BFormFile, BButton, BImg, BFormCheckbox, BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import base64Encode, { getMimeType } from '@/libs/UploadFilesService'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ImageCrop from '@/components/ImageCrop';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormFile,
    BButton,
    BImg,
    BFormCheckbox,
    BFormTextarea,
    ImageCrop
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {},
      progress: 0,
      required,
      imageToCrop: {
        src: null,
        type: null
      },
    }
  },

  created() {
    // data
    this.$http.get('/homepage/secondSection')
        .then(response => {
          this.data = response.data
        })
  },
  methods: {
    loadImage(event) {
      const { files } = event.target;

      if (files && files[0]) {
        if (this.imageToCrop.src) {
          URL.revokeObjectURL(this.imageToCrop.src)
        }

        const blob = URL.createObjectURL(files[0]);

        const reader = new FileReader();

        reader.onload = (e) => {
          this.imageToCrop = {
            src: blob,
            type: getMimeType(e.target.result, files[0].type)
          };
        };

        reader.readAsArrayBuffer(files[0]);

        this.$refs.cropper.show();
      }
    },

    upload(blob) {
      base64Encode(blob)
          .then(value => {
            const config = { headers: { 'Content-Type': 'application/json' } }
            this.$http
                .post('/uploads', JSON.stringify(value), config)
                .then(response => {
                  this.data.photoUrl = response.data;
                })
          })
    },

    clearImageToCrop(event) {
      this.imageToCrop = {
        src: null,
        type: null
      }
    },

    save(isPublish) {
      const valid = this.$refs.validationObserver.validate()
          .then(success => {
            if (success) {
              this.$http.post('/homepage/secondSection', this.data)
                  .then(() => {
                    if (!isPublish) {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Saved Successfully',
                          icon: 'CheckCircleIcon',
                          variant: 'success',
                        },
                      });
                    }
                  })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Check the fields validations above to be able to save!',
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              })
            }
            return success
          })
      return valid
    }
  },
}
</script>
